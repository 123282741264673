import React from "react";
import { Link } from "react-router-dom";
import { jobs } from "./workList";

interface JobsProps {}
//todo add previews and tech/responsibilities, make me more sellable
//todo  make titles of posts more sexy
//make images expand, make 2 pages possible

const Jobs: React.FC<JobsProps> = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        margin: "1rem",
      }}
    >
      {Object.keys(jobs).map((jobKey, index) => {
        const job = jobs[jobKey];
        return (
          <div key={index} className={"job"}>
            <span>
              <p>
                <i> {job.startDate + " - " + job.endDate} </i>
              </p>

              <h5>{job.title} </h5>

              <p>
                <b>{job.company}</b> /{" "}
                {job.location && <span>{job.location}</span>}
              </p>

              <ul>
                {job.link &&
                  job.link.length > 0 &&
                  job.link.map((link) => {
                    return (
                      <li key={link.url}>
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.description}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </span>
            {job.description && <p>{job.description}</p>}
            <Link to={`/work/${job.id}`} className="post-link">
              <u>Details</u>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
export default Jobs;
