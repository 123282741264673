import React, { Fragment, useEffect, useMemo } from "react";
import { projects } from "./workList";
import { Link, useParams } from "react-router-dom";
import { VideoElementYouTubeById } from "../blog/PostVideo";

const Work: React.FC = () => {
  const { id } = useParams();

  const project = useMemo(() => {
    return projects.find((project) => project.id === id);
  }, [id]);

  const youtubeVideoIds = useMemo(() => {
    if (!project?.link?.length) return null;
    const filteredYouTubeLinks = project.link.filter(
      (link) => link.url.indexOf("youtube") > -1
    );
    return filteredYouTubeLinks.map((link) => {
      const url = new URL(link.url);
      return url.searchParams.get("v");
    });
  }, [project]);

  const renderYouTubeVideos = useMemo(() => {
    if (!youtubeVideoIds?.length) return null;

    return youtubeVideoIds.map(
      (videoId, i) =>
        videoId && <VideoElementYouTubeById key={i} videoId={videoId} />
    );
  }, [youtubeVideoIds]);

  const renderProjectDescription = useMemo(() => {
    if (!project?.fullDescription.length) return null;

    return (
      <>
        <h2>My Role</h2>
        {project.fullDescription.map((textBlock, i) => (
          <p key={i}>{textBlock}</p>
        ))}
      </>
    );
  }, [project]);

  const renderProjectTechStack = useMemo(() => {
    if (!project?.techStack.length) return null;

    return (
      <>
        <h2>Tech Stack</h2>
        <ul>
          {project.techStack.map((tech, i) => (
            <li key={i}>{tech}</li>
          ))}
        </ul>
      </>
    );
  }, [project]);

  const renderProjectResponsibilities = useMemo(() => {
    if (!project?.responsibilities.length) return null;

    return (
      <>
        <h2>Responsibilities</h2>
        <ul>
          {project.responsibilities.map((responsibility, i) => (
            <li key={i}>{responsibility}</li>
          ))}
        </ul>
      </>
    );
  }, [project]);

  const renderProjectChallanges = useMemo(() => {
    if (!project?.challanges.length) return null;

    return (
      <>
        <h2>Challanges</h2>
        <ul>
          {project.challanges.map((challange, i) => (
            <li key={i}>{challange}</li>
          ))}
        </ul>
      </>
    );
  }, [project]);

  const renderProjectAchievements = useMemo(() => {
    if (!project?.achievements.length) return null;

    return (
      <>
        <h2>Achievements</h2>
        <ul>
          {project.achievements.map((achievement, i) => (
            <li key={i}>{achievement}</li>
          ))}
        </ul>
      </>
    );
  }, [project]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  //render project details in a nice layout
  return (
    <div className="project blur-bg post-details">
      <Fragment>
        <div className="project-container">
          {" < "}
          <Link to="/"> Back </Link>
          <h1>
            {project?.title} @ {project?.company}
          </h1>
          <p>
            {project?.location} {project?.startDate + " - " + project?.endDate}
          </p>
          <p>{project?.description}</p>
          {project?.link &&
            project?.link.length > 0 &&
            project?.link.map((link, index) => {
              return (
                <span key={link.url}>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.description}
                  </a>
                  {index !== (project.link?.length || 1) - 1 && (
                    <span>{" | "}</span>
                  )}
                </span>
              );
            })}

          <div className="post-details-text">
            {renderProjectDescription}
            {renderProjectTechStack}
            {renderProjectResponsibilities}
            {renderProjectChallanges}
            {renderProjectAchievements}
            {renderYouTubeVideos}
          </div>
        </div>
      </Fragment>
    </div>
  );
};
export default Work;
