//react HOC for horizontal scroll
import React, { useCallback } from "react";

interface IProps {
  scrollAmountOnView?: number;
  children: React.ReactNode;
}

const HScroll: React.FC<IProps> = ({ children, scrollAmountOnView }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isScrollRightEdge, setIsScrollRightEdge] = React.useState(false);
  const scrollableRef = React.useRef<HTMLDivElement>(null);

  const handleScrollRight = useCallback(() => {
    const container = scrollableRef.current;
    if (container) {
      //todo make better logic when edge is reached
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const maxScrollLeft = scrollWidth - clientWidth;
      if (scrollLeft === 0) {
        //console.log("left edge");
      } else if (
        scrollLeft === maxScrollLeft ||
        maxScrollLeft - scrollLeft < 100
      ) {
        // console.log("right edge");
        setIsScrollRightEdge(true);
      }
      container.scrollTo({
        left: scrollLeft + 100,
        behavior: "smooth",
      });
    }
  }, []);

  const handleScrollMostLeft = useCallback(() => {
    const container = scrollableRef.current;
    if (container) {
      container.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
    setIsScrollRightEdge(false);
  }, []);

  const autoscrollWhenReachesViewport = useCallback(() => {
    if (!scrollAmountOnView) return;
    const container = scrollableRef.current;
    //detect if component is in viewport
    if (container) {
      const { top } = container.getBoundingClientRect();
      const vHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (top > 0 && top - vHeight) {
        //console.log("in viewport");

        container.scrollTo({
          left: scrollAmountOnView,
          behavior: "smooth",
        });
        window.removeEventListener("scroll", autoscrollWhenReachesViewport);
      }
    }
  }, [scrollAmountOnView]);

  React.useEffect(() => {
    if (!scrollAmountOnView) return;
    //autoscroll when component is in viewport
    window.addEventListener("scroll", autoscrollWhenReachesViewport);
    return () => {
      window.removeEventListener("scroll", autoscrollWhenReachesViewport);
    };
  }, [autoscrollWhenReachesViewport, scrollAmountOnView]);

  return (
    <div
      className="hscroll__container"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <div
        ref={scrollableRef}
        className={`hscroll ${isHovered ? "show-scroll" : ""}`}
      >
        {children}
      </div>

      {/* arrow that is on the right side of the component and when clicked it scrolls component right */}
      {isScrollRightEdge ? (
        <button onClick={handleScrollMostLeft} className={"hscroll__arrow"}>
          {"<<"}
        </button>
      ) : (
        <button onClick={handleScrollRight} className={"hscroll__arrow"}>
          {">"}
        </button>
      )}
    </div>
  );
};

export default HScroll;
