import React from "react";

const Contact = () => {
  return (
    <>
      <div>
        You can message me on{" "}
        <a href="https://www.linkedin.com/in/timurgolovinov/">linkedin</a> or{" "}
        <a href="mailto:timurvalo@gmail.com">email me</a>.
      </div>
    </>
  );
};

export default Contact;
