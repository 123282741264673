import React from "react";
import { createPortal } from "react-dom";

interface BackgroundI {
  onClick: () => void;
  children?: any;
}
interface ModalI {
  children: any;
  close: () => void;
}

const Background: React.FC<BackgroundI> = ({ children, onClick }) => (
  <div className="background" onClick={onClick}>
    {children}
  </div>
);

const ClickCapturer: React.FC<BackgroundI> = ({ onClick, children }) => (
  <div className="click-capturer" onClick={(e) => e.stopPropagation()}>
    <CloseButton onClick={onClick} />
    {children}
  </div>
);

const CloseButton: React.FC<BackgroundI> = ({ onClick }) => (
  <button type="button" onClick={onClick} className="close">
    {""}
  </button>
);

const FullScreenMedia: React.FC<ModalI> = ({ children, close }) => {
  return createPortal(
    <Background onClick={close}>
      <ClickCapturer onClick={close}>{children}</ClickCapturer>
    </Background>,
    document.body
  );
};

export default FullScreenMedia;
