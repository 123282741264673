import React from "react";

const Error = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <h3>404</h3>
      <p>
        <a href="https://timurgolovinov.com/"> Go back</a>
      </p>
    </div>
  );
};

export default Error;
