export type Job = {
  id: string;
  title: string;
  company: string;
  location: string;
  startDate: string;
  endDate: string;
  description: string;
  link?: { url: string; description: string }[];
};

export type JobDetails = Job & {
  fullDescription: string[];
  images: string[];
  videos: string[];
  techStack: string[];
  responsibilities: string[];
  challanges: string[];
  achievements: string[];
  clientLogos: string[];
};

export const jobs: { [key: string]: Job } = {
  jasper: {
    id: "jasper",
    title: "👨‍💻 Senior Software Engineer",
    company: "Jasper",
    location: "Australia",
    startDate: "Feb 2023",
    endDate: "Present",
    description: `Started as a software engineer in the browser extension team, my role evolved into building full-stack core features of the Jasper platform, including chat, create flow, document editor, analytics, observability, internal tooling for Solution Engineers, and much more.`,

    link: [
      { url: "https://jasper.ai/", description: "Website" },
      {
        url: "https://www.youtube.com/watch?v=loRJkgeRgt4&ab_channel=Jasper",
        description: "Demo video",
      },
    ],
  },
  spacedraft: {
    id: "spacedraft",
    title: "👨‍💻 Software Engineer",
    company: "Spacedraft",
    location: "Western Australia",
    startDate: "Feb 2021",
    endDate: "Oct 2022",
    description:
      "Visualisation software that allows you to create complex animations and simulations in a browser",
    link: [
      { url: "https://www.spacedraft.com/", description: "Website" },
      { url: "https://launch.spacedraft.com/", description: "Actual product" },
      {
        url: "https://www.youtube.com/watch?v=RWtL8MS3gng",
        description: "Demo video",
      },
    ],
  },
  geomoby: {
    id: "geomoby",
    title: "👨‍💻 Software Engineer",
    company: "GeoMoby",
    location: "Western Australia",
    startDate: "Aug 2020",
    endDate: "Feb 2021",
    description:
      "Geospacial intelligence merged with biological data and smart algorithms",
    link: [
      { url: "https://www.geomoby.com/", description: "Website" },
      { url: "https://rac-web.geomoby.com", description: "One of the project" },
      {
        url: "https://www.facebook.com/watch/?v=302376964927823",
        description: "News video",
      },
    ],
  },
  other: {
    id: "other-projects",
    title: "👨‍💻 Software Engineer & Business owner 🕴️",
    company: "Timur Golovinov Technology",
    location: "Western Australia",
    startDate: "Jul 2016",
    endDate: "Feb 2021",
    description:
      "Multiple private clients and my own ventures from many industries including social networks, large scale public art manfacturing, vending machines, beauty, sports, food, real-estate, finance and marketing",
  },
};

//add somewhere senior skills:
// Advanced knowledge of JavaScript language features and concepts, such as prototype-based inheritance, closure, and functional programming.

// Experience with JavaScript libraries and frameworks, such as React, Angular, and Vue.js.

// Knowledge of web development concepts such as browser compatibility, performance optimization, and accessibility.

// Understanding of web technologies such as HTML, CSS, and the Document Object Model (DOM).

// Experience with asynchronous programming, including event loops, callbacks, promises, and async/await.

// Knowledge of webpack, babel, and other tools commonly used in JavaScript development.

// Understanding of design patterns and best practices for structuring and maintaining large-scale JavaScript applications.

// Experience with testing and debugging JavaScript code, including unit testing and end-to-end testing.

// Familiarity with newer JavaScript features and updates, such as ES6, ES7, and ES8.

// Knowledge of the latest web development trends and technologies, such as Progressive Web Apps (PWA), Web Components, and WebAssembly.

// Knowledge of the browser's dev tools and performance analysis.

// Experience with developing and deploying JavaScript applications in a production environment.

// Understanding of security concerns and best practices for web development.

// Experience with version control systems such as Git.

// Strong communication and collaboration skills, as well as the ability to mentor and lead other team members.

export const projects: JobDetails[] = [
  {
    ...jobs.jasper,
    fullDescription: [
      `Development: I’ve built scalable core feature addressing most critical user needs and achieving company’s strategic objectives. These features included Jasper Chat (predecessor of Jasper Co-Pilot), Jasper Templates/Workflows/Tasks, and some features within Jasper Document Editor, among others. I also built tools for our solution engineers that enabled business teams to deliver significant value to enterprise customers, customising product to their unique processes and solving unique problems across many industries and fortune 500 companies.`,
      `Company-wide tech influence: As a key developer, I played a central role in introducing design patterns, industry best practices, optimising performance of critical areas across the stack (metrics include O(n^2) to O(n), or solving gql n+1 problems in high-traffic features), and implementing practices around testing that ensured optimal balance between quality and engineering velocity. I spearheaded initiatives to improve observability, integrate analytics, and drive decisions using a blend of data, customer feedback, and observed behaviour.`,
      `My approach: I consistently adopt a proactive approach to deliver value both to customers and within the company. Regardless of specific areas of responsibility, I always seek ways to enhance the customer experience through thoughtful, data-driven well-crafted features. I also like to continuously identify quick wins, key values and issues from various sources, such as the customer service channel, product feedback/issues, LogRocket, Amplitude, Datadog, and internal communications. I swiftly fix problems, propose and implement new solutions or processes that benefit the team and the organisation. Beyond my defined responsibilities, I proactively address design problems, communicate with stakeholders across different teams, and collaborate cross-functionally to achieve results and get things done. I often manage inbound requests from various teams. I developed a reputation as a go-to problem solver. I am always keen to take on challenging issues, and I feel confident in taking responsibility no matter how hard the problem can be. My experience at Jasper has taught me the fine art of balancing quality and velocity in an extremely competitive market. Working in a company with hundreds of thousands of active customers who expect a premium enterprise experience, I have honed my ability to meet high expectations. Finally, first-principles thinking helps me solve hard problems and design innovative solutions when no effective existing alternatives are available.`,
      `Knowledge: I always seek to strengthen my technical expertise because I am naturally curious and love all things software and systems (Artificial and biological). I am also passionate about neuroscience and psychology, which helps me continuously become better in soft skills. I like being a mentor, and surround myself with great mentors. `,
    ],
    images: [],
    videos: [],
    techStack: [
      "React",
      "NextJS",
      "NodeJS",
      "NestJS",
      "JavaScript",
      "Typescript",
      "GCP",
      "Plasmo",
      "GraphQL",
      "REST",
      "gRPC",
      "SQL",
      "Git",
    ],
    responsibilities: [
      "Build core features of the application",
      "Optimise performance of the application",
      "Develop Blowser extension",
      "Integrate 3rd party platforms",
      "Write tests",
      "Write documentation",
      "Asynchronously and fully remotely collaborate with colleagues",
      "Communicate with stakeholders",
    ],
    challanges: [
      "Ever-evolving, fast-phased AI field",
      "High loads",
      "Support around the world",
    ],
    achievements: [
      "Improved backend and frontend performance in orders of magnitude",
      "Insured scalability, reliability, security and maintainability of the application",
      "Developed core features of the application that are used all App users around the world",
      "Introduced some of the best practices",
      "Identified memory leaks and code inefficiencies",
      "Improved code quality and test coverage",
      "Improved efficiency of test suite",
      "Work across scalability and exploration features",
    ],
    clientLogos: [],
  },

  {
    ...jobs.spacedraft,
    fullDescription: [
      "I had the opportunity to work as a software engineer at one of Perth's most innovative SaaS startups, SpaceDraft, where I was responsible for building, maintaining, and scaling the company's visual planning and communication software. As a member of a small team of 5 engineers, my duties were varied and included primarily coding, gathering requirements, planning and implementing features, writing tests, participating in project management, researching solutions to technical challenges, mentoring and collaborating with colleagues, communicating with a variety of stakeholders including users, testers, designers, and the product owner, and more.",
      "In this role, I was motivated by the opportunity to solve problems at scale, make a positive impact, and continually improve my skills. I was also motivated by the company's success, including raising $2.7 million in investment and a growing user base that loves the product. As a core engineer, I needed to have a strong understanding of the full technical stack and the ability to solve problems across it, as well as communicate effectively with team members who may have less technical expertise.",
      "Our technical stack includes Node, REST, GraphQL, AWS amplify + around 15 other services (EMC, Cloudfront, EC2, and others) on our backend and react, (redux + context) typescript on frontend as well as end-to-end tests using cypress.",
      "Some of my notable achievements at SpaceDraft included the development of video-on-demand capabilities with a sophisticated permissions framework, a geospatial map solution for a government grant that became a core feature of the app, multitrack audio playback and editing, significant contributions to the design and development of the app's UI/UX, the implementation of a paywall with Stripe, and the development of numerous features based on user feedback.",
    ],
    techStack: [
      "React",
      "Redux",
      "Context",
      "Node",
      "15+ AWS Services",
      "Typescript",
      "GraphQL",
      "REST",
      "Cypress",
      "Web APIs",
      "ArcGIS",
      "HTML",
      "CSS",
      "JavaScript",
      "Git",
    ],
    responsibilities: [
      "Participate in full app lifecycle: concept, design, build, deploy, test and release",
      "Developing new features",
      "Implementation of browser/device interoperability",
      "Backend and Frontend performance optimisation",
      "Maintaining and refactoring existing features",
      "Enforcing high standards of code quality & best practices",
      "Writing tests",
      "Researching solutions to technical challenges",
      "Communicating with stakeholders",
      "Collaborating with colleagues",
      "Participating in project management & planning",
      "Mentoring and training junior engineers",
    ],
    challanges: [
      "Device and browser interoperability for high-performance application with multiple canvas layers (Animation, Geospacial 3rd Party API, Asset Layers) and web APIs (such as WebRTC)",
      "Developing a complex permission system for video-on-demand feature with multiple roles (e.g. admin, editor, viewer) and multiple levels of access (e.g. read, write, delete)",
      "Implementing a paywall with Stripe with multiple payment options (e.g one-off, sunscriptions, etc) and classes of users (e.g. free, premium, enterprise)",
      "Maintaining and refactoring existing codebase with limiting black-box solutions (such as early versions of AWS amplify)",
      "Implementing new features with limited time and resources, strong balance between quality and speed",
      "Working in ambiguous environments with limited requirements and frequent changes",
      "Implementing resource-intensive features with limited resources (e.g. CPU, GPU, RAM, etc)",
      "Refactoring system from being CPU bound (too many things causing lags) to be real-time-bound (e.g. reduce fps when system overloads)",
      "Pioneering unprecedented features (multimedia playback and view, geospatial map, animating canvas (time + space), etc) with limited documentation and examples",
    ],
    achievements: [
      "Developed robust video-on-demand capabilities (serveless system for video upload, transcoding for adaptive bitrate + mono format, artifact generation, manifest editing for permissions) with a sophisticated permissions framework",
      "Implemented a geospatial map solution for a government grant that became a core feature of the app (included performance optimisation of ArcGis solutions to enable support of older devices, bundle optimisation, and multiple features such as FeatureLayers, Places, Routes, geocoding, 3rd party data integration, basemap changes, screenshoting and many others)",
      "Developed multitrack audio playback and editing using Web Audio API (WebRTC, RecordRTC library), and Audio Context",
      "Made significant contributions to the design and development of the app's UI/UX",
      "Implemented a paywall with Stripe",
      "Developed numerous features based on user feedback",
      "Architected and implemented numerous fullstack features",
      "Improved the app's performance and scalability by making significant refactoring and improvements to the codebase",
      "Designed and implemented multi-track audio playback and editing",
      "Optimised multiple processes of app's lifecycle such as build (optimise bundle size), deploy (decrease manual operations needed for AWS deployment), and release",
    ],
    images: [],
    videos: [],
    clientLogos: [],
  },
  {
    ...jobs.geomoby,
    fullDescription: [
      "I worked on web-based software that combines geospatial intelligence with biological signals, acquired through custom wearables + phones.",
      "This combination of hardware and software allowed us to bring new insights and perspectives for our commercial clients, including innovation branches of high-profile companies.",
      "In addition, our users were people working in high-risk jobs such as emergency response workers and mining workers, whose biological states and physical locations are crucial in risky situations.",
      "Finally, we also created mobile app + webapp that tracks people driving and lifestyle habits with the goal of improving them by providing direct financial incentives. This project was a collaboration with RAC, and you can learn more about it in this article https://geomoby.com/geomoby-and-rac-team-up-to-improve-lives-and-provide-a-positive-impact/",
      "Tech: React frontend + typescript, openlayers.",
      "One of the highlights of my time at GeoMoby was the successful development and deployment of a set of features for a web app that we presented at a major mining emergency response competition. Under a high-pressure environment, I worked with the design and product team to quickly and seamlessly develop a React and AWS solution that was robust enough to impress the end-users and secure two enterprise client contracts. This was a crucial moment for the company's reputation and financial success, and the feedback we received at the event was incorporated into future iterations of our core product.",
    ],

    techStack: [
      "React",
      "Openlayers",
      "Typescript",
      "HTML",
      "CSS",
      "JavaScript",
      "Git",
      "Fp-ts",
    ],
    responsibilities: [
      "Participate in full app lifecycle: concept, design, build, deploy, test and release",
      "Development of new user-facing elements and integration with server-side logic",
      "Designing, developing, and maintaining efficient, reusable, and reliable library of React components",
      "Optimisation of the application for maximum speed and scalability",
      "Implementation of security and data protection",
      "Help to improve code quality through writing unit tests, automation and performing code reviews",
      "Participate in brainstorming sessions and contribute innovative and original ideas to GeoMoby's technology, algorithms and product",
      "Work with the product and design team to understand end-user requirements and use cases, then translate that into a pragmatic and effective technical solution",
      "Write and maintain documentation for all features in development",
    ],
    challanges: [
      "Develop multiple applications with asynchronous team members and multiple stakeholders",
      "Follow a strict development process with a high level of quality assurance as per client's design, security and data-management guidelines",
      "Work in ambiguous environments and use creative problem-solving skills to identify complex problems and develop innovative solutions that are relevant to the end-user and their domain",
      "Refactor legacy code, then integrate it and develop new high quality code in a fast-paced environment",
    ],
    achievements: [
      "Developed a set of features for a web app that we presented at a major mining emergency response competition",
      "Contributed creative ideas to GeoMoby's technology, algorithms and product, implemented them and presented them to the team",
      "Successfully architected and developed a web application's frontend with React and Typescript, which code was reused for mobile application as well (via webview)",
      "Independently built a set of reusable React components that were used in multiple projects",
    ],
    images: [],
    videos: [],
    clientLogos: [],
  },
  {
    ...jobs.other,
    fullDescription: [
      "As an entrepreneur with a passion for innovation in the fields of human health and wellbeing, I have always been fascinated by the intersection of technology and biology. My interests lie particularly in the areas of brain-computer interfaces (BCI), biodevices, neuroscience, and psychology. I believe that these cutting-edge technologies have the potential to revolutionize the way we understand and improve human health. Over the past three years, I have dedicated myself to a number of personal projects related to these areas, exploring the latest developments and opportunities in the field. My background in business has also given me the experience and skills needed to bring these projects to life, including managing a small vending machine business in Australia which I successfully sold. As I continue to pursue my entrepreneurial journey, I am driven by a desire to make a positive impact on the world and to create innovative solutions that improve people's lives. I am always seeking new challenges and opportunities to learn and grow, and I am excited about what the future holds for the fields of human health and wellbeing.",
      "In addition to my passion for innovation in the field of human health and wellbeing, I have also built a successful career serving private clients in the tech industry. Throughout my career, I have worked with a wide range of clients on projects ranging from web-app development to infrastructure migration to the cloud, and general consulting. My strong technical skills and deep understanding of the latest technologies have made me a sought-after advisor for businesses looking to stay ahead of the curve. I have a proven track record of delivering high-quality work and exceeding my clients' expectations, making me a valuable asset to any team or organization. As a forward-thinking individual, I am always seeking new opportunities to expand my skillset and make a difference in the world. Whether I am working on a personal project in the field of human health and wellbeing or consulting with a private client on a tech-related project, I am driven by a passion for innovation and a desire to make a positive impact on the world.",
    ],
    techStack: [
      "JavaScript",
      "TypeScript",
      "React",
      "React Native",
      "Material UI",
      "AWS",
      "Amplify",
      "REST",
      "Node",
      "Python",
      "Brainflow",
      "Git",
    ],
    responsibilities: [],
    challanges: [
      // "BCI: Navigate cmplex field of BCI and neuroscience",
      // : "Clients:",
    ],
    achievements: [
      // "BCI: Signal processing (artifact removal, bandpass filtering, noise reduction, experiments on performance etc)",
      // "Clients:",
    ],
    images: [],
    videos: [],
    clientLogos: [],
  },
];
