import React from "react";
import FullScreenMedia from "../layout/FullScreenMedia";
import HScroll from "../layout/HScroll";

interface CertificatesProps {}

const certs = [
  {
    id: 90,
    name: "Google Cloud - Digital Leader",
    url: "img/certs/09-google-cloud.jpeg",
  },
  {
    id: 100,
    name: "NCAN Certificate neurotechnology",
    url: "img/certs/1-ncan.png",
  },
  {
    id: 110,
    name: "Gtec Certificate neurotechnology",
    url: "img/certs/2-gtec.jpg",
  },
  {
    id: 120,
    name: "Perth biodesign Certificate",
    url: "img/certs/3-biodesign.png",
  },
  {
    id: 130,
    name: "Brain Computer Interface Certificate",
    url: "img/certs/4-bci.jpeg",
  },
  {
    id: 140,
    name: "Neural signal processing and analysis Certificate",
    url: "img/certs/5-signalprocessing.jpeg",
  },
  {
    id: 200,
    name: "Curtin University Masters Degree of Internet Communications",
    url: "img/certs/6-masters.jpg",
  },
  {
    id: 210,
    name: "Diploma in Software Development",
    url: "img/certs/7-diploma.jpg",
  },
  {
    id: 220,
    name: "Certificate IV in Programming",
    url: "img/certs/8-cer.jpg",
  },
  {
    id: 300,
    name: "Nest JS Certificate",
    url: "img/certs/17-nestjs.jpeg",
  },
  {
    id: 310,
    name: "SQL Certificate",
    url: "img/certs/18-sql.jpg",
  },
  {
    id: 320,
    name: "Relational database design Certificate",
    url: "img/certs/19-rdb-design.jpg",
  },
  {
    id: 9,
    name: "Senior web developer certificate",
    url: "img/certs/9-webdev.jpeg",
  },
  {
    id: 10,
    name: "Design rules: Principles + Practices for Great UI Design",
    url: "img/certs/10-design.jpeg",
  },
  {
    id: 11,
    name: "AWS Modern Applications Deploy apps reliably, rapidly, and at scale",
    url: "img/certs/11-aws.png",
  },
  {
    id: 12,
    name: "Uniernet of things UI",
    url: "img/certs/12-iot.jpg",
  },
  {
    id: 13,
    name: "Certificate in Neural Networks",
    url: "img/certs/13-neuralnets.jpg",
  },
  {
    id: 14,
    name: "Certificate in Machile Learning",
    url: "img/certs/14-ml.jpg",
  },
  {
    id: 15,
    name: "Certificate for social enterprise",
    url: "img/certs/15-BigIssue.jpg",
  },
  {
    id: 16,
    name: "Certificate for enterpreneurship",
    url: "img/certs/16-CERI.png",
  },
];

const Certificates: React.FC<CertificatesProps> = () => {
  return (
    <HScroll>
      {certs.map((cert) => {
        return <Certificate key={cert.id} cert={cert} />;
      })}
    </HScroll>
  );
};

interface CertificateProps {
  cert: {
    id: number;
    name: string;
    url: string;
  };
}

//component that renders image and on click opens it on full screen as a pop up
const Certificate: React.FC<CertificateProps> = ({ cert }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <img
        className="cert"
        src={cert.url}
        alt={cert.name}
        onClick={() => setOpen((state) => !state)}
      />
      {open && (
        <FullScreenMedia close={() => setOpen((state) => !state)}>
          <img
            src={cert.url}
            alt={cert.name}
            onClick={() => setOpen((state) => !state)}
          />
        </FullScreenMedia>
      )}
    </>
  );
};

export default Certificates;
