import React from "react";
import About from "./about/About";
import Certificates from "./about/Certificates";
import Jobs from "./projects/Jobs";

const Landing: React.FC = () => {
  return (
    <React.Fragment>
      <About />
      <Jobs />
      <Certificates />
    </React.Fragment>
  );
};

export default Landing;
