import React from "react";
import Contact from "../contact/Contact";

const About: React.FC = () => {
  return (
    <div className="about">
      <div className="flex about-container">
        <img src="/img/TimGolovinov.jpg" alt="Tim Golovinov Protrait" />
        <div className="about-text">
          <div>
            <h1>Timur Golovinov</h1>
            <p>
              {" "}
              I am a senior software engineer and technical leader with a deep
              passion for building sophisticated, scalable, and high-performance
              web applications. Throughout my career, I’ve built products that
              integrate AI, complex visualisations, wearable & IoT, and cloud
              technologies. My extensive experience includes deep technical
              work, leading teams to deliver core features and architecting
              solutions that serve millions of users, consistently driving
              strategic business outcomes.
            </p>
            <p>
              At Jasper, I am at the forefront of development of high-impact
              features, such Create Flow (Templates), Document Editor, and Chat,
              which have become critical components of the product's success. I
              take pride in my proactive approach to problem-solving, whether
              it’s optimising performance across the stack, improving
              observability, or integrating analytics to make data-driven
              decisions.
            </p>
            <p>
              My technical expertise spans a broad range of tools and
              technologies, including Typescript, Node, React, and cloud
              platforms like GCP/AWS. I am tool-agnostic, always selecting the
              most appropriate technology to address specific business
              challenges. My commitment to continuous learning keeps me at the
              forefront of the industry, with a keen interest in neuroscience
              and psychology to enhance my leadership and mentoring skills.
            </p>
            <p>
              Outside of work, I enjoy kitesurfing and exploring the
              intersection of human behaviour and technology, which further
              informs my approach to creating intuitive and user-centred
              products. As a technical leader, I am dedicated to not only
              building world-class software but also cultivating a culture of
              innovation, excellence, and collaboration within my teams.
            </p>

            {/* <p>
              I am a software engineer with extensive experience in building
              sophisticated and scalable products. Throughout my career, I’ve
              built web applications incorporating AI, complex visualisations,
              wearable & IoT, web & cloud. My founder-mindset allows me to focus
              on the most critical business problems, deliver maximum value to
              customers, and take responsibility for the most ambitious
              forward-looking projects.
            </p>
            <p>
              I spend most of my free time enjoying ocean sports, learning about
              human psychology and behavior, exploring neuroscience and AI, and
              hanging out with my loved ones.
            </p> */}
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
