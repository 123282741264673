import React from "react";

const Policies = () => {
  return (
    <div>
      <h2>Privacy Policy </h2>
      <p>
        Timur Golovinov is committed to providing quality services to you and
        this policy outlines our ongoing obligations to you in respect of how we
        manage your Personal Information. We have adopted the Australian Privacy
        Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy
        Act). The NPPs govern the way in which we collect, use, disclose, store,
        secure and dispose of your Personal Information. A copy of the
        Australian Privacy Principles may be obtained from the website of The
        Office of the Australian Information Commissioner at www.aoic.gov.au
      </p>
      <br></br>
      <h2>What is Personal Information and why do we collect it?</h2>
      <p>
        Personal Information is information or an opinion that identifies an
        individual. Examples of Personal Information we collect include: names,
        addresses, email addresses, and phone. This Personal Information is
        obtained in many ways including current website and google analytics. We
        collect your Personal Information for the primary purpose of providing
        our services to you, providing information to our clients and marketing.
        We may also use your Personal Information for secondary purposes closely
        related to the primary purpose, in circumstances where you would
        reasonably expect such use or disclosure. You may unsubscribe from our
        mailing/marketing lists at any time by contacting us in writing. When we
        collect Personal Information we will, where appropriate and where
        possible, explain to you why we are collecting the information and how
        we plan to use it.
      </p>
      <br></br>
      <h2>Sensitive Information</h2>
      <p>
        Sensitive information is defined in the Privacy Act to include
        information or opinion about such things as an individual's racial or
        ethnic origin, political opinions, membership of a political
        association, religious or philosophical beliefs, membership of a trade
        union or other professional body, criminal record or health information.
        Sensitive information will be used by us only: For the primary purpose
        for which it was obtained For a secondary purpose that is directly
        related to the primary purpose With your consent; or where required or
        authorised by law.
      </p>
      <br></br>
      <h2>Third Parties</h2>
      <p>
        Where reasonable and practicable to do so, we will collect your Personal
        Information only from you. However, in some circumstances we may be
        provided with information by third parties. In such a case we will take
        reasonable steps to ensure that you are made aware of the information
        provided to us by the third party.
      </p>
      <br></br>
      <h2>Disclosure of Personal Information</h2>
      <p>
        Your Personal Information may be disclosed in a number of circumstances
        including the following: Third parties where you consent to the use or
        disclosure; and Where required or authorised by law.
      </p>
      <br></br>
      <h2>Security of Personal Information</h2>
      <p>
        Your Personal Information is stored in a manner that reasonably protects
        it from misuse and loss and from unauthorized access, modification or
        disclosure. When your Personal Information is no longer needed for the
        purpose for which it was obtained, we will take reasonable steps to
        destroy or permanently de-identify your Personal Information. However,
        most of the Personal Information is or will be stored in client files
        which will be kept by us for a minimum of 7 years.
      </p>
      <br></br>
      <h2>Access to your Personal Information</h2>
      <p>
        You may access the Personal Information we hold about you and to update
        and/or correct it, subject to certain exceptions. If you wish to access
        your Personal Information, please contact us in writing. Timur Golovinov
        Technology will not charge any fee for your access request, but may
        charge an administrative fee for providing a copy of your Personal
        Information. In order to protect your Personal Information we may
        require identification from you before releasing the requested
        information.
      </p>
      <br></br>
      <h2>Maintaining the Quality of your Personal Information</h2>
      <p>
        It is an important to us that your Personal Information is up to date.
        We will take reasonable steps to make sure that your Personal
        Information is accurate, complete and up-to-date. If you find that the
        information we have is not up to date or is inaccurate, please advise us
        as soon as practicable so we can update our records and ensure we can
        continue to provide quality services to you.
      </p>
      <br></br>
      <h2>Cookie Policy</h2>
      <p>
        BY CONTINUING TO USE OUR SITE AND SERVICES, YOU ARE AGREEING TO THE USE
        OF COOKIES AND SIMILAR TECHNOLOGIES FOR THE PURPOSES WE DESCRIBE IN THIS
        PRIVACY POLICY. IF YOU DO NOT ACCEPT THE USE OF COOKIES AND SIMILAR
        TECHNOLOGIES, DO NOT USE THIS SITE. <br></br>
        What is a cookie? <br></br>A cookie is a simple text file that is stored
        on your computer or mobile device by a website’s server. Each cookie is
        unique to your web browser. It will contain some anonymous information
        such as a unique identifier and the site name and some digits and
        numbers. Most websites you visit use cookies to improve your user
        experience by allowing the website to ‘remember’ you, either for the
        duration of your visit (using a ‘session cookie’) or for repeat visits
        (using a ‘persistent cookie’). Cookies may be set by the website you are
        visiting (‘first party cookies’) or they may be set by other websites
        who run content on the page you are viewing (‘third party cookies’).
        What do cookies do? <br></br>
        Cookies have lots of different jobs, like letting you navigate between
        pages efficiently, storing your preferences, and improving your
        experience of a website. Cookies make the interaction between you and
        the website faster and easier. If a website doesn’t use cookies, it will
        think you are a new visitor every time you move to a new page on the
        site, for example, even after you “log in,” if you move to another page
        it won’t recognise you and it won’t be able to keep you logged in.
        <br></br>
      </p>
      <h3>How do we use cookies?</h3>
      <h5>Security</h5>
      <p>
        We use cookies to enable and support our security features, for example:
        to authenticate Members, prevent fraudulent use of login credentials,
        and protect Member data from unauthorized parties.
      </p>
      <h5>Preferences</h5>

      <p>
        When you are signed in to your account, cookies help us display the
        correct information and personalize your experience, by providing you
        with features, insights, and customized content. They can also help you
        fill out forms more easily, e.g., RFI forms. Loss of the information
        stored in a preference cookie may make the Site experience less
        functional, but should not prevent it from working.
      </p>
      <h5>Session State</h5>
      <p>
        We collect information about how our Users and Members use and interact
        with the Site. This may include the pages Members visit most often and
        when and where Members get error messages. We use these “session state
        cookies” to help us improve our Site and Services. Blocking or deleting
        these cookies will not prevent the Site from working.
      </p>
      <h5>Analytics</h5>
      <p>
        These cookies help us learn how our Site performs in different
        locations. We use cookies to understand and improve our Services and
        features.
      </p>
      <h2>What third-party cookies do we use?</h2>
      <p>
        Analytics companies like Google Analytics place cookies on your device.
      </p>
      <h2>What should you do if you don’t want cookies to be set?</h2>
      <p>
        Some people find the idea of a website storing information on their
        computer or mobile device to be intrusive, particularly when this
        information is stored and used by a third party without them knowing.
        Although cookies are generally quite harmless, you may not, for example,
        want to see advertising that has been targeted to your interests using
        your browser history. If you prefer, you may choose to block some or all
        cookies, or even to delete cookies that have already been set; but you
        should be aware that you might lose some functions of the website{" "}
        <br></br>
        If you want to restrict or block the cookies that are set by our Site,
        or any other site, you can do so through your browser setting. The
        ‘Help’ function in your browser should explain how. Alternatively, you
        can visit www.aboutcookies.org, which contains comprehensive information
        on how to do this on a wide variety of browsers. You will find general
        information about cookies and details on how to delete cookies from your
        machine.
        <br></br>
        To opt-out of third-parties collecting any data regarding your
        interaction on our Site, please refer to their websites for further
        information.
      </p>
      <h2>Policy Updates</h2>
      <p>
        This Policy may change from time to time and is available on our
        website.
      </p>
      <br></br>
      <h2>Privacy Policy Complaints and Enquiries</h2>
      <p>
        If you have any queries or complaints about our Privacy Policy please
        contact us at: timurvalo@gmail.com +61422587179
      </p>
      <br></br>
    </div>
  );
};

export default Policies;
