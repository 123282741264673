import ReactDOM from "react-dom/client";
import "./assets/styles/App.scss";
import * as serviceWorker from "./serviceWorker";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import Policies from "./components/documentation/Policies";
import Error from "./components/documentation/Error";
import Work from "./components/projects/Work";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/policies",
    element: <Policies />,
  },
  {
    path: "/work/:id",
    element: <Work />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

serviceWorker.register();
