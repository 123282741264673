import React, { Fragment, useState, useEffect } from "react";
import Spinner from "../layout/Spinner";

type YouTubeVideo = {
  kind: "youtube#playlistItem";
  etag: string;
  id: string;
  snippet: {
    publishedAt: Date;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      (): {
        url: string;
        width: number;
        height: number;
      };
    };
    channelTitle: string;
    videoOwnerChannelTitle: string;
    videoOwnerChannelId: string;
    playlistId: string;
    position: number;
    resourceId: {
      kind: string;
      videoId: string;
    };
  };
  contentDetails: {
    videoId: string;
    startAt: string;
    endAt: string;
    note: string;
    videoPublishedAt: Date;
  };
  status: {
    privacyStatus: string;
  };
};

const PostVideo: React.FC = () => {
  const [videos, setVideos] = useState<YouTubeVideo[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  //get youtube videos
  useEffect(() => {
    (async () => {
      const res = await fetch(
        "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=50&playlistId=UUmggIZkAvAIyuQX40ZQ2c8w&key=AIzaSyC9Qg12ej7jtf9APDzXRySLUZLh-WYuK58"
      );
      const data = await res.json();
      setVideos(data.items);
      setIsLoading(false);
    })();
  }, []);

  return isLoading ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      {videos.length > 0 &&
        videos.map((video) => (
          <VideoElementYouTubeById
            key={video.id}
            videoId={video.contentDetails.videoId}
          />
        ))}
    </Fragment>
  );
};

interface IPropsVideoElement {
  videoId: string;
}

export const VideoElementYouTubeById: React.FC<IPropsVideoElement> = ({
  videoId,
}) => {
  // const [open, setOpen] = React.useState(false);

  const videoMemoised = React.useMemo(
    () => (
      <div className="posts-video-item">
        <iframe
          title="Timur Golovinov YouTube Video"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ),
    [videoId]
  );

  return (
    <>
      {videoMemoised}
      {/* {open && (
        <FullScreenMedia close={() => setOpen((state) => !state)}>
          {videoMemoised}
        </FullScreenMedia>
      )} */}
    </>
  );
};

export default PostVideo;
